import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Stepper from '../../components/molecules/Stepper';
import AppHeader from '../../components/organisms/AppHeader';
import { MAPPINGS } from '../../consts/mapping';
import { useConstants } from '../../context/ConstantsContext';
import MetaContext from '../../context/MetaContext';
import { useUser } from '../../context/UserContext';
import { arrayPluck } from '../../helpers/json';
import { beautify } from '../../helpers/str';
import { listConstants } from '../../services/_constants.service';
import { getLead } from '../../services/_crm.service';
import OnboardingAddresses from './OnboardingAddresses';
import OnboardingBank from './OnboardingBank';
import OnboardingBusiness from './OnboardingBusiness';
import OnboardingDocuments from './OnboardingDocuments';
import OnboardingGst from './OnboardingGst';
import OnboardingInit from './OnboardingInit';
import OnboardingPan from './OnboardingPan';
import OnboardingPartners from './OnboardingPartners';
import OnboardingPerson from './OnboardingPerson';
import OnboardingSearchGst from './OnboardingSearchGst';
import OnboardingValidate from './OnboardingValidate';
import { routes } from './config';

const Onboarding = ({ page = true }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { user } = useUser();
    const { setMeta } = useContext(MetaContext);
    const { cacheConstants } = useConstants();
    const [initialized, setInitialized] = useState(false);
    const [lead, setLead] = useState({});
    const [step, setStep] = useState(0);
    const [steps, setSteps] = useState([]);

    const getConstants = async () => {
        try {
            const response = await listConstants();
            cacheConstants(response);
        } catch (error) {
        } finally {
            setInitialized(true);
        }
    };

    const initLead = async () => {
        if (user.lead_id) {
            const response = await getLead(user.lead_id);
            setLead(response);
            buildSteps(response);
        }
    };

    const handleRefresh = () => {
        initLead();
    };

    const buildSteps = (response) => {
        let tmp = [];
        if (user?.program_config?.borrower?.borrower_type !== 'salaried') {
            tmp = [
                { name: 'Business Details', path: routes.business },
                { name: 'KYC Documents', path: routes.documents },
                { name: 'KYC Details', path: routes.person },
            ];
        } else {
            tmp.push = [
                { name: 'KYC Documents', path: routes.documents },
                { name: 'KYC Details', path: routes.person },
            ];
        }
        tmp = buildPartner(tmp, response?.business_entity?.business_type);
        tmp = buildBank(tmp);
        setSteps(tmp);
        setStep(arrayPluck(tmp, 'path').indexOf(location.pathname));
    };

    const buildPartner = (tmp, business_type) => {
        const partnersLabels = MAPPINGS.crego.partners_label;
        if (Object.keys(partnersLabels).indexOf(business_type) !== -1) {
            tmp.push({
                name: beautify(partnersLabels[business_type] + 's'),
                path: routes.partners,
            });
        } else if (
            user?.program_config?.borrower?.co_applicants?.requirement === 'yes'
        ) {
            tmp.push({
                name: 'Co-Applicant',
                path: routes.partners,
            });
        }
        return tmp;
    };

    const buildBank = (tmp) => {
        if (user?.program_config?.borrower?.business?.banks?.length > 0) {
            tmp.push({
                name: 'Bank',
                path: routes.bank,
            });
        }
        return tmp;
    };

    const handleNext = () => {
        if (step + 1 >= steps.length) {
            navigate(routes.validate);
        } else {
            navigate(steps[step + 1].path);
        }
    };

    useEffect(() => {
        getConstants();
        initLead();
        setMeta({ title: 'KYC | Crego for Borrowers' });
    }, []);

    useEffect(() => {
        initLead();
        if (
            location.pathname.indexOf('business') !== -1 &&
            user?.program_config?.borrower?.borrower_type === 'salaried'
        ) {
            navigate(routes.documents);
        }
    }, [navigate]);

    return (
        <>
            {initialized && (
                <>
                    {page && <AppHeader logout={true} />}
                    <div className={`${page ? 'content' : ''}`}>
                        {location.pathname !== routes.home &&
                            location.pathname !== routes.gst &&
                            location.pathname !== routes.pan && (
                                <Stepper
                                    className='mt-20 mx-30'
                                    active={step}
                                    items={steps}
                                />
                            )}
                        <Routes>
                            <Route
                                index
                                element={<OnboardingInit />}></Route>
                            <Route
                                path='/gst'
                                element={<OnboardingGst lead={lead} />}></Route>
                            <Route
                                path='/search-gst'
                                element={<OnboardingSearchGst />}></Route>
                            <Route
                                path='/pan'
                                element={<OnboardingPan lead={lead} />}></Route>
                            <Route
                                path='/business'
                                element={
                                    <OnboardingBusiness
                                        lead={lead}
                                        onNext={handleNext}
                                    />
                                }></Route>
                            <Route
                                path='/documents'
                                element={
                                    <OnboardingDocuments
                                        onRefresh={handleRefresh}
                                        lead={lead}
                                        onNext={handleNext}
                                    />
                                }></Route>
                            <Route
                                path='/person'
                                element={
                                    <OnboardingPerson
                                        onRefresh={handleRefresh}
                                        lead={lead}
                                        onNext={handleNext}
                                    />
                                }></Route>
                            <Route
                                path='/partners'
                                element={
                                    <OnboardingPartners
                                        lead={lead}
                                        onNext={handleNext}
                                        onRefresh={initLead}
                                    />
                                }></Route>
                            <Route
                                path='/person-details'
                                element={
                                    <OnboardingPartners
                                        lead={lead}
                                        onNext={handleNext}
                                        onRefresh={initLead}
                                    />
                                }></Route>
                            {/* <Route
                                path='/documents'
                                element={<OnboardingDocs />}></Route> */}
                            <Route
                                path='/addresses'
                                element={<OnboardingAddresses />}></Route>
                            <Route
                                path='/bank'
                                element={
                                    <OnboardingBank onNext={handleNext} />
                                }></Route>
                            <Route
                                path='/validate'
                                element={<OnboardingValidate />}></Route>
                        </Routes>
                    </div>
                </>
            )}
        </>
    );
};

export default Onboarding;
