import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import NavBar from '../../components/molecules/NavBar';
import AppHeader from '../../components/organisms/AppHeader';
import { useConstants } from '../../context/ConstantsContext';
import MetaContext from '../../context/MetaContext';
import { useUser } from '../../context/UserContext';
import { listConstants } from '../../services/_constants.service';
import { getLead } from '../../services/_crm.service';
import Account from './Account';
import Agreements from './Agreements';
import GstAnalytics from './GstAnalytics';
import Home from './Home';
import Loans from './Loans';
import Records from './Records';
import Topups from './Topups';
import { root } from './config';
import './styles.scss';
import ItrAnalytics from './ItrAnalytics';
import AppContext from '../../context/AppContext';

const BorrowerApp = () => {
    const { user } = useUser();
    const { setMeta } = useContext(MetaContext);
    const { cacheConstants } = useConstants();
    const [lead, setLead] = useState(null);
    const [initialized, setInitialized] = useState(false);
    const { setApp } = useContext(AppContext);

    const getConstants = async () => {
        try {
            const response = await listConstants();
            cacheConstants(response);
        } catch (error) {
        } finally {
            setInitialized(true);
        }
    };

    const initLead = async () => {
        const response = await getLead(user.lead_id);
        setLead(response);
    };

    useEffect(() => {
        getConstants();
        initLead();
        setMeta({ title: 'Crego for Borrowers' });
        setApp({ back_url: '/onboarding/documents' });
    }, []);

    return (
        <>
            {initialized && (
                <>
                    <AppHeader logout={lead?.status !== 'active'} />
                    <div className='content'>
                        <Routes>
                            <Route
                                index
                                element={<Home />}></Route>
                            <Route
                                path='topups/*'
                                element={<Topups />}></Route>
                            <Route
                                path='loans/*'
                                element={<Loans />}></Route>
                            <Route
                                path='records/*'
                                element={<Records />}></Route>
                            <Route
                                path='agreements'
                                element={<Agreements />}></Route>
                            <Route
                                path='account/*'
                                element={<Account />}></Route>
                            <Route
                                path='gst-analytics/*'
                                element={<GstAnalytics />}></Route>
                            <Route
                                path='itr-analytics/*'
                                element={<ItrAnalytics />}></Route>
                        </Routes>
                    </div>
                    {lead?.status === 'active' && (
                        <NavBar
                            config={{
                                root,
                                items: [
                                    {
                                        name: 'home',
                                        icon: 'home-smile-2-line',
                                        title: 'Home',
                                        link: '',
                                    },
                                    ...(user?.service_accounts?.lms?.id
                                        ? [
                                              {
                                                  name: 'topups',
                                                  icon: 'bill-line',
                                                  title: 'Topups',
                                                  link: 'topups',
                                              },
                                          ]
                                        : []),
                                    ...(user?.service_accounts?.lms?.id
                                        ? [
                                              {
                                                  name: 'records',
                                                  icon: 'cash-line',
                                                  title: 'Payments',
                                                  link: 'records',
                                              },
                                          ]
                                        : []),
                                    ...(user?.service_accounts?.los?.id
                                        ? [
                                              {
                                                  name: 'agreements',
                                                  icon: 'shield-star-line',
                                                  title: 'Agreement',
                                                  link: 'agreements',
                                              },
                                          ]
                                        : []),
                                    {
                                        name: 'account',
                                        icon: 'account-circle-line',
                                        title: 'My Account',
                                        link: 'account',
                                    },
                                ],
                            }}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default BorrowerApp;
