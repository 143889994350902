import axios from 'axios';
import { buildUrl } from '../helpers/str';

export const BASE_URL =
    (process.env.REACT_APP_API_ROOT_DISBURSE ||
        process.env.REACT_APP_API_ROOT_DEFAULT) + '/lms/';

export const urls = {
    topups: BASE_URL + 'topups/',
    topup_details: BASE_URL + 'topups/:id',

    approvals: BASE_URL + 'approvals/',
    approval_details: BASE_URL + 'approvals/:id/',

    line_details: BASE_URL + 'line_details/',
};

export const listTopups = async (params = null) => {
    const { data } = await axios.get(urls.topups, {
        params: { ...params },
    });
    return data;
};

export const listApprovals = async (params = null) => {
    const { data } = await axios.get(urls.approvals, {
        params: { ...params },
    });
    return data;
};

export const approveInvoice = async (id) => {
    const { data } = await axios.put(
        buildUrl(urls.approval_details, { id }) + 'approve/',
    );
    return data;
};

export const rejectInvoice = async (id, remark) => {
    const { data } = await axios.put(
        buildUrl(urls.approval_details, { id }) + 'reject/',
        { remark },
    );
    return data;
};

export const getTopup = async (id) => {
    const { data } = await axios.get(buildUrl(urls.topup_details, { id }));
    return data;
};

export const deleteTopup = async (id) => {
    const { data } = await axios.delete(buildUrl(urls.topup_details, { id }));
    return data;
};

export const lineDetails = async (type, lead_id, program_id) => {
    const { data } = await axios.get(
        buildUrl(urls.line_details, {
            type,
            lead_id,
            program_id,
        }),
    );
    return data;
};
