import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoaderAnimated from '../../components/atoms/LoaderAnimated';
import PageHeader from '../../components/molecules/PageHeader';
import FormWrapper from '../../components/organisms/FormView/FormWrapper';
import { useUser } from '../../context/UserContext';
import { urls } from '../../services/_crm.service';
import { changeProfile } from '../../services/_identity.service';
import { routes } from './config';
import { getProgramConfig } from '../../services/_datum.service';
import { alertError } from '../../helpers/alert';

const OnboardingPan = ({ lead }) => {
    const { user, loginUser } = useUser();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [request, setRequest] = useState({});
    const handleUpdated = (data) => {
        setRequest(data);
    };

    const handleError = (error) => {
        if (!error.status_code) {
            alertError(error.message);
        }
        if (
            error.status_code === 'ACCOUNT_ALREADY_EXISTS' &&
            error?.id === user?.lead_id
        ) {
            switchProfile({ lead_id: error.id, program_id: user.program_id });
        } else if (error.status_code === 'ACCOUNT_ALREADY_EXISTS') {
            alertError(error.message);
        }
    };

    const switchProfile = async (profile) => {
        const payload = {
            lead_id: profile.lead_id,
            program_id: profile.program_id,
        };
        const response = await changeProfile(payload);
        if (response?.access_token) {
            localStorage.setItem(
                process.env.REACT_APP_LOCAL_STORAGE_AUTH,
                response.access_token,
            );
            payload.program_config = await getProgramConfig(profile.program_id);
            loginUser(payload);
            navigate(routes.business + '?source=pan');
        }
    };

    const handleSubmitted = (response) => {
        if (response.id) {
            switchProfile({
                lead_id: response.id,
                program_id: user.program_id,
            });
        }
    };

    useEffect(() => {
        localStorage.setItem('back_url', '/onboarding');
    }, []);

    return (
        <div
            className='container init'
            style={{ marginTop: -100 }}>
            <PageHeader
                className='p-0 pb-20'
                subtitle='Enter your PAN number to start your application'></PageHeader>
            {loading && <LoaderAnimated loading={loading} />}
            <FormWrapper
                submitted={handleSubmitted}
                onError={handleError}
                config={{
                    silent: true,
                    consent: true,
                    url: urls.onboarding_leads,
                    btn: 'Start Application',
                    items: [
                        ...(user?.program_config?.borrower?.borrower_type ===
                        'salaried'
                            ? []
                            : [
                                  {
                                      type: 'select',
                                      name: 'business_type',
                                      options_src: 'constants',
                                      options: 'business_types',
                                  },
                              ]),
                        { name: 'pan_no' },
                    ],
                }}
                updated={handleUpdated}
                data={request}
            />
        </div>
    );
};

export default OnboardingPan;
