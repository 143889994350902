import React, { useContext, useEffect } from 'react';
import Icon from '../../components/atoms/Icon';
import PageHeader from '../../components/molecules/PageHeader';
import { routes } from './config';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../../context/UserContext';
import AppContext from '../../context/AppContext';

const OnboardingInit = () => {
    const { user } = useUser();
    const navigate = useNavigate();
    const { setApp } = useContext(AppContext);
    const links = [
        {
            icon: 'keyboard-box-line',
            text: 'I have GST Number',
            path: routes.gst,
        },
        { icon: 'skip-right-line', text: "I don't have GST", path: routes.pan },
    ];

    useEffect(() => {
        if (user?.program_config?.borrower?.borrower_type === 'salaried') {
            navigate(routes.pan);
        }
        setApp({ hide_back: true });
    }, []);

    return (
        <div className='container'>
            <PageHeader
                className='p-0 pb-20'
                subtitle="Let's get Started"></PageHeader>
            <div className='list list-bg list-auto-height'>
                {links.map((link) => {
                    return (
                        <Link
                            to={link.path}
                            className='list__item mb-0 clickable'
                            key={link.text}>
                            <div
                                className={`list__item__icon list__item__icon-sm bg-success`}>
                                <Icon name={link.icon} />
                            </div>
                            <div className='list__item__content'>
                                {link.text}
                            </div>
                        </Link>
                    );
                })}
            </div>
        </div>
    );
};

export default OnboardingInit;
