import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoaderAnimated from '../../components/atoms/LoaderAnimated';
import { useUser } from '../../context/UserContext';
import { getLead } from '../../services/_crm.service';
import { routes } from '../Onboarding/config';
import AgreementsSummary from './Agreements/AgreementsSummary';
import CreditLineV2 from './Dashboard/CreditLineV2';
import GstAnalyticsSummary from './GstAnalytics/GstAnalyticsSummary';
import ItrAnalyticsSummary from './ItrAnalytics/ItrAnalyticsSummary';
import DueLoans from './Loans/DueLoans';
import LoanOutstandingBreakup from './Loans/LoanOutstandingBreakup';
import PaymentForm from './Records/PaymentForm';
import TopupApprovals from './Topups/TopupApprovals';
import { lineDetails } from '../../services/_disburse.service';

const Home = () => {
    const navigate = useNavigate();
    const { user } = useUser();
    const [lead, setLead] = useState(null);
    const [line, setLine] = useState(null);
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    const serviceAccounts = user?.service_accounts;
    const [businessConfig, setBusinessConfig] = useState({});

    const initLead = async () => {
        const response = await getLead(user.lead_id);
        if (response?.status === 'kyc_pending') {
            navigate(routes.business);
        }
        if (
            response?.lead?.status === 'active' &&
            user?.service_accounts?.los?.id
        ) {
            navigate('/borrower/agreements');
        }
        let business_config = {};
        user?.program_config?.borrower?.business.forEach((row) => {
            if (
                row.tags.indexOf(response?.business_entity?.business_type) !==
                -1
            ) {
                business_config = row;
            }
        });
        setBusinessConfig(business_config);
        setLead(response);
    };

    const initLineDetails = async () => {
        const response = await lineDetails(
            'borrower_program',
            user.lead_id,
            user.program_id,
        );
        setLine(response);
    };

    const errorMap = {
        crm: {
            rejected: {
                message:
                    'Your application has been rejected. Please contact your account manager',
                animation: 'rejected',
                loop: false,
            },
            kyc_completed: {
                message:
                    'Your KYC has been submitted and will be reviewed shortly',
            },
            kyc_verified: {
                message:
                    'Your KYC has been approved. Your application is being reviewd for credit worthiness',
            },
            kyc_approval: {
                message:
                    'Your KYC has been submitted and will be reviewed shortly',
            },
            bre_prending: {
                message:
                    'Your KYC has been submitted and will be reviewed shortly',
            },
            bre_approval: {
                message:
                    'Your KYC has been submitted and will be reviewed shortly',
            },
            pending_underwriting: {
                message:
                    'Your KYC has been submitted and will be reviewed shortly',
            },
            pending: {
                message:
                    'Your KYC has been submitted and will be reviewed shortly',
            },
            kyc_verification: {
                message:
                    'Your KYC has been submitted and will be reviewed shortly',
            },
        },
        los: {
            pending: {
                message:
                    'Your application is being reviewed by our Underwriter. Your credit line will be generated shortly',
            },
            initiated: {
                message:
                    'Credit line has been generated and is pending for approval. The below credit line is subject to change as per the final review by underwriter',
            },
        },
    };

    useEffect(() => {
        initLead();
        initLineDetails();
    }, []);
    return (
        <div className='container'>
            {!line?.approved_line_amount &&
                serviceAccounts?.los?.status === 'initiated' && (
                    <div className='mb-20'>
                        <h4 className='mb-5'>
                            Credit line has been generated and is pending for
                            approval.
                        </h4>
                        <p className='color-secondary'>
                            The below credit line is subject to change as per
                            the final review by underwriter
                        </p>
                    </div>
                )}

            {['pending'].indexOf(serviceAccounts?.los?.status) !== -1 &&
                !line?.approved_line_amount && (
                    <LoaderAnimated
                        className='loader-static'
                        loading={true}
                        name={
                            errorMap.los[serviceAccounts.los.status]
                                .animation || 'searching'
                        }
                        title={errorMap.los[serviceAccounts.los.status].message}
                        loop={
                            errorMap.los[serviceAccounts.los.status].loop ||
                            true
                        }
                    />
                )}
            {([
                'rejected',
                'kyc_completed',
                'kyc_verified',
                'kyc_verification',
                'kyc_approval',
                'bre_prending',
                'bre_approval',
                'pending_underwriting',
                'pending',
            ].indexOf(lead?.programs?.[0]?.status) !== -1 ||
                [
                    'rejected',
                    'kyc_completed',
                    'kyc_verified',
                    'kyc_verification',
                    'kyc_approval',
                    'bre_prending',
                    'bre_approval',
                    'pending_underwriting',
                    'pending',
                ].indexOf(lead?.status) !== -1) &&
                !line?.approved_line_amount && (
                    <LoaderAnimated
                        className='loader-static'
                        loading={true}
                        name={
                            errorMap?.crm?.[lead?.programs?.[0]?.status]
                                ?.animation ||
                            errorMap?.crm?.[lead?.status]?.animation ||
                            'searching'
                        }
                        title={
                            errorMap?.crm?.[lead?.programs?.[0]?.status]
                                ?.message ||
                            errorMap?.crm?.[lead?.status]?.message
                        }
                        loop={
                            errorMap?.crm?.[lead?.programs?.[0]?.status]
                                ?.loop ||
                            errorMap?.crm?.[lead?.status]?.loop ||
                            true
                        }
                    />
                )}
            {line?.approved_line_amount > 0 && (
                <CreditLineV2
                    line={{
                        used: line.principal_outstanding,
                        available: line.active_line_amount,
                        approved: line.approved_line_amount,
                    }}
                />
            )}
            {businessConfig?.financials?.required?.indexOf('gst_return') !==
                -1 &&
                lead?.business_entity?.gstin && (
                    <GstAnalyticsSummary
                        applicant_id={lead?.business_entity?.id}
                    />
                )}
            {businessConfig?.financials?.required?.indexOf(
                'itr_ackowledgement',
            ) !== -1 &&
                lead?.business_entity?.pan_no && (
                    <ItrAnalyticsSummary
                        applicant_id={lead?.business_entity?.id}
                    />
                )}
            {(lead?.programs?.[0]?.tags.indexOf('los.signing.pending') !== -1 ||
                lead?.programs?.[0]?.tags.indexOf('los.agreement.pending') !==
                    -1) && <AgreementsSummary />}
            {serviceAccounts?.lms?.id && <TopupApprovals />}
            {serviceAccounts?.lms?.id && <DueLoans />}

            {line?.total_outstanding > 0 && (
                <>
                    <h4 className='mt-20'>Outstanding</h4>
                    <LoanOutstandingBreakup
                        className='mt-10'
                        summary={line}
                    />
                    <button
                        onClick={() => setShowPaymentForm(true)}
                        className='mt-10 btn'>
                        Make Repayment
                    </button>
                    <PaymentForm
                        show={showPaymentForm}
                        onClose={() => setShowPaymentForm(false)}
                        config={{
                            amount: line?.total_outstanding,
                        }}
                    />
                </>
            )}
        </div>
    );
};

export default Home;

/*  
    Lead
        rejected -> Your application has been rejected. Please contact your account manager
        kyc_completed -> Your KYC has been submitted and will be reviewed shortly.
        kyc_verified -> Your KYC has been approved. Your application is being reviewd for credit worthiness.
        active -> 
    Application 
        pending -> Your application is being reviewed by our Underwriter. Your credit line/loan will be generated shortly
        initiated -> Credit line has been generated and is pending for approval. The below credit line is subject to change as per the final review by underwriter
        sanctioned -> Your credit line agreement has being generated. 
        activated ->
        dropped ->
    Disburse Account
        active ->
    Accounting Account
        active ->

*/
